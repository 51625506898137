import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiListResponse } from '@maplix/api';
import { IOrganisation, IOrganisationLicense, IOrganisationMember, IUserDetails } from '@maplix/utils';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';
import { getPermissions } from './role-permissions';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AppInitService {
  public organisation: IOrganisation;

  public activeLicense: IOrganisationLicense | null = null;

  public projectLicenses: IOrganisationLicense[] = [];

  public user: IUserDetails;

  public member: IOrganisationMember;

  public async load(
    cookie: CookieService,
    http: HttpClient,
    environment: any,
    translate: TranslateService,
    permissionsService: NgxPermissionsService
  ) {
    const progressBar = document.querySelector('.maplix-loading-progress') as HTMLDivElement;
    const activeWorkspaceJSON = cookie.get(`activeWorkspace${environment.env}`);
    const activeWorkspace = activeWorkspaceJSON ? JSON.parse(activeWorkspaceJSON) : null;

    if (!activeWorkspace) {
      throw TypeError('No organisation');
    }

    if (progressBar) {
      progressBar.style.width = '25%';
    }

    const org = await http
      .get<IOrganisation>(`${environment.api}organisations/${activeWorkspace._id}`)
      .pipe(
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (!org) {
      throw TypeError('No organisation');
    }

    this.organisation = org;
    if (progressBar) {
      progressBar.style.width = '40%';
    }

    const activeLicenses = await http
      .get<IOrganisationLicense | IOrganisationLicense[]>(
        `${environment.api}organisations/${activeWorkspace._id}/active_licenses`
      )
      .pipe(
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (this.organisation.type !== 'CONSULTANCY' && !activeLicenses) {
      throw TypeError('No active license');
    } else if (this.organisation.type !== 'CONSULTANCY' && activeLicenses) {
      this.activeLicense = activeLicenses as IOrganisationLicense;
    } else if (this.organisation.type === 'CONSULTANCY' && activeLicenses) {
      this.projectLicenses = activeLicenses as IOrganisationLicense[];
    }

    if (progressBar) {
      progressBar.style.width = '60%';
    }

    const userDetailsJSON = cookie.get(`userDetails${environment.env}`);
    const userDetails = userDetailsJSON ? JSON.parse(userDetailsJSON) : null;

    if (!userDetails) {
      throw TypeError('No user');
    }

    const user = await http
      .get<IUserDetails>(`${environment.api}users/${userDetails._id}`)
      .pipe(
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (!user) {
      throw TypeError('No user');
    }

    this.user = user;

    if (progressBar) {
      progressBar.style.width = '80%';
    }

    const member = await http
      .get<IApiListResponse<IOrganisationMember>>(
        `${environment.api}organisations/${org._id}/members?where={"user":"${user._id}"}`
      )
      .pipe(
        map(({ _items }) => _items[0]),
        catchError((err) => {
          throw TypeError(err);
        })
      )
      .toPromise();

    if (!member) {
      throw TypeError('No member');
    }

    this.member = member;

    if (!this.member.roles || !this.member.roles.length) {
      throw TypeError('No member roles');
    }

    const roles = [...this.member.roles];

    if (this.user.roles?.length) {
      roles.push(...this.user.roles);
    }

    const permissions = getPermissions(
      this.organisation,
      roles,
      this.activeLicense ? [this.activeLicense] : this.projectLicenses
    );
    console.log(permissions);

    // load permissions with ngx-permissions
    permissionsService.loadPermissions(permissions);

    await translate.use(org.defaultLanguage?.code);

    if (progressBar) {
      progressBar.style.width = '100%';
    }

    return true;
  }
}
