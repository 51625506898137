import { IOrganisation, IOrganisationLicense, LicenseModule, LicenseType } from '@maplix/utils';

interface IRolePermissions {
  [key: string]: {
    module?: LicenseModule;
    licenseTypes?: LicenseType[];
    organisationTypes?: string[];
    permissions: string[];
  }[];
}

const ROLE_PERMISSIONS: IRolePermissions = {
  SUPERUSER: [{ permissions: ['superuser'] }],
  ADMIN: [
    { permissions: ['viewSettings', 'editOrganisation', 'editMembers'] },
    { module: LicenseModule.ACTIONPLANS, permissions: ['editRolesActionplan'] },
    { module: LicenseModule.SURVEYS, permissions: ['editRolesSurvey'] },
    { organisationTypes: ['CONSULTANCY'], permissions: ['editRolesSurvey'] },
  ],
  ACTIONPLAN_EDITOR: [
    {
      module: LicenseModule.ACTIONPLANS,
      permissions: [
        'viewActionplan',
        'createActionplan',
        'editActionplan',
        'deleteActionplan',
        'uploadActionplan',
        'exportActionplan',
        'importActionplan',
        'viewPlanning',
        'editPlanning',
        'viewGoal',
        'editGoal',
        'createGoal',
        'terminateActionplan',
      ],
    },
    // {
    //   organisationTypes: ['CONSULTANCY'],
    //   module: LicenseModule.ACTIONPLANS,
    //   permissions: [
    //     'viewActionplan',
    //     'createActionplan',
    //     'editActionplan',
    //     'deleteActionplan',
    //     'uploadActionplan',
    //     'exportActionplan',
    //     'importActionplan',
    //     'pushActionplan',
    //     'terminateActionplan'
    //   ],
    // },
    {
      module: LicenseModule.COMMUNICATION,
      licenseTypes: [LicenseType.SUBSCRIPTION, LicenseType.PROJECT],
      permissions: ['publishActionplan', 'terminateActionplan'],
    },
  ],
  ACTIONPLAN_ADVISOR: [
    { module: LicenseModule.ACTIONPLANS, permissions: ['viewActionplan', 'commentActionplan'] },
    { organisationTypes: ['CONSULTANCY'], permissions: ['viewActionplan', 'commentActionplan'] },
  ],
  SURVEY_EDITOR: [
    {
      module: LicenseModule.SURVEYS,
      permissions: [
        'viewSurvey',
        'createSurvey',
        'editSurvey',
        'duplicateSurvey',
        'archiveSurvey',
        'deleteSurvey',
        'terminateSurvey',
      ],
    },
    {
      module: LicenseModule.SURVEYS,
      licenseTypes: [LicenseType.SUBSCRIPTION, LicenseType.PROJECT],
      organisationTypes: ['GOV_LOCAL'],
      permissions: ['publishSurvey', 'terminateSurvey'],
    },
    {
      module: LicenseModule.SURVEYS,
      licenseTypes: [LicenseType.PROJECT],
      organisationTypes: ['CONSULTANCY'],
      permissions: ['publishProjectSurvey', 'terminateProjectSurvey'],
    },
    {
      organisationTypes: ['CONSULTANCY'],
      permissions: [
        'viewSurvey',
        'createSurvey',
        'editSurvey',
        'duplicateSurvey',
        'deleteSurvey',
        'pushSurvey',
        'terminateProjectSurvey',
      ],
    },
  ],
  MAP_EDITOR: [
    { permissions: ['viewMap', 'createMap', 'editMap', 'duplicateMap', 'deleteMap', 'exportMap'] },
    { module: LicenseModule.COMMUNICATION, permissions: ['publishMap'] },
  ],
};

export function getPermissions(organisation: IOrganisation, roles: string[], licenses: IOrganisationLicense[] = []) {
  const permissions: string[] = [];

  roles.forEach((role) => {
    if (licenses?.length) {
      licenses.forEach((license) => {
        permissions.push(...getRolePermissions(role, organisation, license));
      });
    } else {
      permissions.push(...getRolePermissions(role, organisation));
    }
  });

  return [...new Set(permissions)];
}

function getRolePermissions(role: string, organisation: IOrganisation, license?: IOrganisationLicense) {
  const permissions = [];

  ROLE_PERMISSIONS[role].forEach((permissionArray) => {
    let allowed = false;
    // When module AND organisationtype AND licensetype is set
    if (
      permissionArray.module &&
      permissionArray.organisationTypes &&
      permissionArray.licenseTypes &&
      license?.modules.includes(permissionArray.module) &&
      permissionArray.organisationTypes.includes(organisation.type) &&
      permissionArray.licenseTypes.includes(license?.type)
    ) {
      allowed = true;
    }
    // When module AND organisationtype is set
    else if (
      permissionArray.module &&
      permissionArray.organisationTypes &&
      license?.modules.includes(permissionArray.module) &&
      permissionArray.organisationTypes.includes(organisation.type) &&
      !permissionArray.licenseTypes
    ) {
      allowed = true;
    }
    // When module AND licensetype is set
    else if (
      permissionArray.module &&
      permissionArray.licenseTypes &&
      license?.modules.includes(permissionArray.module) &&
      permissionArray.licenseTypes.includes(license?.type) &&
      !permissionArray.organisationTypes
    ) {
      allowed = true;
    }
    // When organisationtype AND licensetype is set
    else if (
      permissionArray.organisationTypes &&
      permissionArray.licenseTypes &&
      permissionArray.organisationTypes.includes(organisation.type) &&
      permissionArray.licenseTypes.includes(license?.type) &&
      !permissionArray.module
    ) {
      allowed = true;
    }
    // When module is set
    else if (
      permissionArray.module &&
      license?.modules.includes(permissionArray.module) &&
      !permissionArray.organisationTypes &&
      !permissionArray.licenseTypes
    ) {
      allowed = true;
    }
    // When organisationtype is set
    else if (
      permissionArray.organisationTypes &&
      permissionArray.organisationTypes.includes(organisation.type) &&
      !permissionArray.module &&
      !permissionArray.licenseTypes
    ) {
      allowed = true;
    }
    // When licensetype is set
    else if (
      permissionArray.licenseTypes &&
      permissionArray.licenseTypes.includes(license?.type) &&
      !permissionArray.module &&
      !permissionArray.organisationTypes
    ) {
      allowed = true;
    }
    // When no module or organisationtype or licensetype is set
    else if (!permissionArray.module && !permissionArray.organisationTypes && !permissionArray.licenseTypes) {
      allowed = true;
    }

    if (allowed) {
      permissions.push(...permissionArray.permissions);
    }
  });

  return permissions;
}
