<div
  class="card mb-2 position-relative grid-card"
  [class.resize-mobile]="resizeOnMobile"
  [class.small-layout]="layoutSize === 'small'"
  [class.border-primary]="image && isSelected"
  [class.checked]="isSelected"
  [class.clickable]="!disabled"
  (click)="onClickCard()"
  [ngStyle]="bottomBorder ? { 'border-bottom': '4px solid ' + bottomBorder } : {}"
>
  <ng-container *ngIf="(badgeText || badgeIcon) && layoutSize != 'small'">
    <div
      class="floating-badge"
      [class.mr-4]="(onDelete | hasObservers) || (onInfo | hasObservers) || (onClickDropdown | hasObservers)"
    >
      <div [class]="badgeClass">
        <i *ngIf="badgeIcon" [class]="badgeIconClass"></i>
        <div [class]="resizeOnMobile ? 'd-none d-md-block' : ''">{{ badgeText }}</div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="onDelete | hasObservers">
    <button
      class="btn btn-xs btn-danger icon-btn floating-button"
      (click)="onClickDelete()"
      ngbTooltip="Delete"
      placement="left"
    >
      <i class="remixicon-delete-bin-6-line"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="onInfo | hasObservers">
    <button
      class="btn btn-xs btn-light icon-btn floating-button"
      (click)="onClickInfo()"
      ngbTooltip="Details"
      placement="left"
    >
      <i class="remixicon-information-line"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="onClickDropdown | hasObservers">
    <div class="floating-button" ngbDropdown (openChange)="onOpenDropdown()">
      <button class="btn btn-xs btn-light icon-btn" ngbDropdownToggle>
        <i class="mdi mdi-dots-horizontal"></i>
      </button>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let item of dropdownItems">
          <ng-container *ngxPermissionsOnly="item.permissions">
            <button ngbDropdownItem *ngIf="!item.confirmOptions" (click)="onDropdownAction(item.action)">
              <div [class]="'d-flex align-items-center ' + item.customClass">
                <i *ngIf="item.icon" [class]="item.icon + ' mr-1'"></i>
                {{ item.label }}
              </div>
            </button>
            <maplix-confirm-button
              *ngIf="item.confirmOptions"
              [buttonType]="item.confirmOptions?.buttonType"
              buttonSize="sm"
              [popoverTitle]="item.confirmOptions?.popoverTitle"
              [popoverMessage]="item.confirmOptions?.popoverMessage"
              [confirmText]="item.confirmOptions?.confirmText"
              [isDropdownItem]="true"
              placement="bottom-left"
              (click)="onOpenDropdownConfirm()"
              (confirm)="onDropdownAction(item.action)"
            >
              <div [class]="'d-flex align-items-center ' + item.customClass">
                <i *ngIf="item.icon" [class]="item.icon + ' mr-1'"></i>
                {{ item.label }}
              </div>
            </maplix-confirm-button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="border-bottom card-icon-header overflow-hidden" [class.large]="size === 'lg'">
    <div
      [class.pt-2]="!image"
      [class.px-3]="!image"
      [class.bg-soft-primary]="isSelected"
      *ngIf="!headerTemplate"
      class="d-flex justify-content-center w-100 h-100"
    >
      <i [class]="icon + ' text-white'" *ngIf="icon && !image && !isSelected"></i>
      <img [src]="image" *ngIf="image" [class.large]="size === 'lg'" />
      <div class="image-overlay" *ngIf="image"></div>
      <i class="fe-check text-primary" *ngIf="isSelected && !image"></i>
    </div>
    <ng-container *ngIf="headerTemplate">
      <div class="image-overlay image-overlay-map"></div>
      <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
    </ng-container>
  </div>
  <div class="card-body" [class.py-2]="size === 'lg'" [class.p-2]="size !== 'lg'">
    <div class="d-flex align-items-center" *ngIf="type">
      <div class="font-12 text-uppercase text-primary">{{ type }}</div>
      <div class="ml-2 d-flex align-items-center" *ngIf="typeHelp" (click)="onClickTypeHelp()">
        <i
          class="remixicon-question-line clickable text-muted"
          [ngbPopover]="typeHelp"
          placement="right-bottom"
          [container]="typeHelpContainer"
          triggers="mouseenter:mouseleave click"
        ></i>
      </div>
    </div>

    <ng-container *ngIf="(badgeText || badgeIcon) && layoutSize === 'small'">
      <div class="d-inline-block mb-1">
        <span [class]="badgeClass">
          <i *ngIf="badgeIcon" [class]="badgeIconClass"></i>
          <div>{{ badgeText | map: truncText:25 }}</div>
        </span>
      </div>
    </ng-container>

    <h5 [class]="'text-ellipsis mt-1 mb-1 ' + nameClass" [class.text-primary]="isSelected">
      {{ name | map: truncText }}
    </h5>
    <div class="font-12 text-muted" [innerHTML]="description | map: truncText:400"></div>
  </div>
</div>
