import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ToastrNotification,
  ISurvey,
  ObjectId,
  MaplixApp,
  ITheme,
  IOrder,
  IMapResult,
  ISurveyReport,
  IOrganisation,
  INotification,
  IOrganisationMember,
  IOrganisationMemberEmbedded,
  IProject,
  IActionPlan,
  IUpload,
  IActionCommentThread,
  IActionComment,
  ActionCommentStatus,
  IOrganisationGoal,
  IOrganisationLicense,
} from '@maplix/utils';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map } from 'rxjs/operators';

export interface IApiListResponse<K> {
  _items: K[];
  _meta: {
    total: number;
    page: number;
    max_results: number;
  };
}

export interface IApiListRequestOptions {
  page: number;
  pageSize: number;
  searchTerm?: string;
  filter?: { [key: string]: string };
}

@Injectable()
export class ApiService {
  private notification: ToastrNotification;

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    toastr: ToastrService,
    private domSanitizer: DomSanitizer
  ) {
    this.notification = new ToastrNotification(toastr);
  }

  public organisation = {
    getOrganisationById: (orgId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations/${orgId}`);
    },
    getOrganisationStats: (orgId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations/${orgId}/stats`);
    },
    getOrganisationGoals: (
      orgId: ObjectId,
      opts: IApiListRequestOptions
    ): Observable<IApiListResponse<IOrganisationGoal>> => {
      const { page, pageSize, filter, searchTerm } = opts;
      const projection = `{"_id": 1, "status": 1, "title": 1, "category": 1, "deadline": 1, "attachments": 1, "pillars": 1, "kpis": 1, "_created": 1, "_updated": 1}`;

      let filterString = '';
      let searchString = '';
      if (filter) {
        filterString = `, "status": "${filter.status}"`;
      }
      if (searchTerm) {
        searchString = `, "title.value":{"$regex":".*(?i)${searchTerm}.*"}`;
      }
      const whereString = `&where={"status": {"$ne": "DELETED"}${filterString}${searchString}}`;

      return this.http.get<IApiListResponse<IOrganisationGoal>>(
        `${this.environment.api}organisations/${orgId}/goals?max_results=${pageSize}&page=${page}&projection=${projection}${whereString}`
      );
    },
    getOrganisationGoalById: (orgId: ObjectId, goalId: ObjectId): Observable<IOrganisationGoal> => {
      return this.http.get<IOrganisationGoal>(`${this.environment.api}organisations/${orgId}/goals/${goalId}`);
    },
    saveOrganisationGoal: (orgId: ObjectId, goal: Partial<IOrganisationGoal>, goalId?: ObjectId): Observable<any> => {
      if (goalId) {
        return this.http.patch(`${this.environment.api}organisations/${orgId}/goals/${goalId}`, goal);
      }

      return this.http.post(`${this.environment.api}organisations/${orgId}/goals`, goal);
    },
    getOrganisationsByName: (name: string): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations?where={"name":"${name}"}&max_results=1`);
    },
    getOrganisationsUriAvailability: (uri: string): Observable<{ available: boolean }> => {
      return this.http.get<{ available: boolean }>(`${this.environment.api}organisations/uri/${uri}`);
    },
    createOrganisation: (org: any): Observable<any> => {
      return this.http.post(`${this.environment.api}organisations`, org);
    },
    getOrganisationsByUserid: (): Observable<any> => {
      return this.http.get(`${this.environment.api}users/me/organisations`);
    },
    updateOrganisation: (orgId: string, org: any): Observable<any> => {
      return this.http.patch(`${this.environment.api}organisations/${orgId}`, org);
    },
    getMembers: (orgId: string): Observable<IApiListResponse<IOrganisationMemberEmbedded>> => {
      return this.http.get<IApiListResponse<IOrganisationMemberEmbedded>>(
        `${this.environment.api}organisations/${orgId}/members?embedded={"user":1}`
      );
    },
    inviteMember: (orgId: string, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}organisations/${orgId}/invite`, data);
    },
    getMemberById: (orgId: string, memberId: string): Observable<IOrganisationMemberEmbedded> => {
      return this.http.get<IOrganisationMemberEmbedded>(
        `${this.environment.api}organisations/${orgId}/members/${memberId}?embedded={"user":1}`
      );
    },
    updateMemberRoles: (orgId: string, memberId: string, roles: string[]): Observable<any> => {
      return this.http.patch(`${this.environment.api}organisations/${orgId}/members/${memberId}`, { roles });
    },
    getLicenseById: (orgId: string, licenseId: string): Observable<IOrganisationLicense> => {
      return this.http.get<IOrganisationLicense>(`${this.environment.api}organisations/${orgId}/licenses/${licenseId}`);
    },
  };

  public user = {
    updateOrganisation: (orgId: string): Observable<any> => {
      return this.http.post(`${this.environment.api}users/me/organisations`, {
        organisation: orgId,
      });
    },
    updateUserDetails: (userId: ObjectId, data: any): Observable<any> => {
      return this.http.patch(`${this.environment.api}users/${userId}`, data);
    },
    getUserById: (userId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}users/${userId}`);
    },
    getRecents: (userId: ObjectId, orgId: ObjectId, embed?: string[]): Observable<any> => {
      let embeddedString = '';
      if (embed) {
        embeddedString = `&embedded={${embed.map((e) => `"${e}": 1`)}}`;
      }

      return this.http.get(
        `${this.environment.api}users/${userId}/recents?where={"organisation":"${orgId}"}${embeddedString}`
      );
    },
    updateRecents: (userId: ObjectId, data: any, recentId: string): Observable<any> => {
      if (recentId) {
        return this.http.patch(`${this.environment.api}users/${userId}/recents/${recentId}`, data);
      }

      return this.http.post(`${this.environment.api}users/${userId}/recents`, data);
    },
  };

  public projects = {
    getProjects: (opts: IApiListRequestOptions): Observable<IApiListResponse<IProject>> => {
      let { page, pageSize, searchTerm } = opts;
      let filter = '&where={"_archived": null}';
      if (searchTerm) {
        filter = `&where={"_archived": null, "general.title.value":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }

      const projection = `&projection={"_id": 1, "general.thumbnail": 1, "general.title": 1, "status": 1}`;
      return this.http.get<IApiListResponse<IProject>>(
        `${this.environment.api}projects?max_results=${pageSize}&page=${page}&sort=[("_updated", -1)]${projection}${filter}`
      );
    },
    getProjectById: (id: ObjectId): Observable<IProject> => {
      return this.http.get<IProject>(`${this.environment.api}projects/${id}`);
    },
    saveProject: (data: IProject, projectId?: ObjectId): Observable<any> => {
      if (projectId) {
        return this.http.put<any>(`${this.environment.api}projects/${projectId}`, data);
      }
      return this.http.post<any>(`${this.environment.api}projects`, data);
    },
    getProjectPartners: (id: ObjectId): Observable<any> => {
      return this.http.get<any>(`${this.environment.api}projects/${id}/partners`);
    },
    getProjectPartnerById: (project: ObjectId, partner: ObjectId): Observable<any> => {
      return this.http.get<any>(`${this.environment.api}projects/${project}/partners/${partner}`);
    },
    inviteProjectPartner: (project: ObjectId, data: any): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}projects/${project}/partners/invite`, data);
    },
    updateProjectPartner: (project: ObjectId, partner: ObjectId, data: any): Observable<void> => {
      return this.http.patch<void>(`${this.environment.api}projects/${project}/partners/${partner}`, data);
    },
    getFilesByProject: (projectId: ObjectId, opts: IApiListRequestOptions): Observable<IApiListResponse<IUpload>> => {
      return this.http.get<IApiListResponse<IUpload>>(
        `${this.environment.api}uploads?where={"project":"${projectId}"}&max_results=${opts.pageSize}&page=${opts.page}`
      );
    },
  };

  public actionPlans = {
    getActionPlans: (opts: IApiListRequestOptions): Observable<IApiListResponse<IActionPlan>> => {
      let { page, pageSize, searchTerm } = opts;
      let filter = '&where={"_archived": null}';
      if (searchTerm) {
        filter = `&where={"_archived": null, "general.title.value":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }
      const projection = `&projection={"_id": 1, "reference": 1, "general.thumbnail": 1, "general.title": 1, "status": 1}`;

      return this.http.get<IApiListResponse<IActionPlan>>(
        `${this.environment.api}actionplans?max_results=${pageSize}&page=${page}&sort=[("_updated", -1)]${projection}${filter}`
      );
    },
    getActionPlansWithActions: (opts: IApiListRequestOptions): Observable<IApiListResponse<IActionPlan>> => {
      let { page, pageSize, searchTerm } = opts;
      let filter = '&where={"_archived": null}';
      if (searchTerm) {
        filter = `&where={"_archived": null, "general.title.value":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }
      const projection = `&projection={"_id": 1, "reference": 1, "general.thumbnail": 1, "general.title": 1, "status": 1, "actions": 1}`;

      return this.http.get<IApiListResponse<IActionPlan>>(
        `${this.environment.api}actionplans?max_results=${pageSize}&page=${page}&sort=[("_updated", -1)]${projection}${filter}`
      );
    },
    getActionPlan: (actionplanId: ObjectId): Observable<IActionPlan> => {
      return this.http.get<IActionPlan>(`${this.environment.api}actionplans/${actionplanId}`);
    },
    saveActionPlan: (data: Partial<IActionPlan>, actionPlanId?: ObjectId): Observable<any> => {
      if (!actionPlanId) {
        return this.http.post(`${this.environment.api}actionplans`, data);
      }

      return this.http.patch(`${this.environment.api}actionplans/${actionPlanId}`, data);
    },
    getFilesByActionPlan: (
      actionPlanId: ObjectId,
      opts: IApiListRequestOptions
    ): Observable<IApiListResponse<IUpload>> => {
      return this.http.get<IApiListResponse<IUpload>>(
        `${this.environment.api}uploads?where={"actionplan":"${actionPlanId}"}&max_results=${opts.pageSize}&page=${opts.page}`
      );
    },
    getFilesByAction: (
      actionPlanId: ObjectId,
      actionId: string,
      opts: IApiListRequestOptions
    ): Observable<IApiListResponse<IUpload>> => {
      return this.http.get<IApiListResponse<IUpload>>(
        `${this.environment.api}uploads?where={"actionplan":"${actionPlanId}", "action":"${actionId}"}&max_results=${opts.pageSize}&page=${opts.page}`
      );
    },
    getCommentThreads: (actionPlanId: ObjectId): Observable<IApiListResponse<IActionCommentThread>> => {
      return this.http.get<IApiListResponse<IActionCommentThread>>(
        `${this.environment.api}actionplans/${actionPlanId}/comment_threads?where={"status":{"$ne":"${ActionCommentStatus.RESOLVED}"}}&embedded={"author":1}`
      );
    },
    getComments: (actionPlanId: ObjectId, threadId?: ObjectId): Observable<IApiListResponse<IActionComment>> => {
      let filter = '';
      if (threadId) {
        filter = `&where={"thread":"${threadId}"}`;
      }
      return this.http.get<IApiListResponse<IActionComment>>(
        `${this.environment.api}actionplans/${actionPlanId}/comments?embedded={"author":1}${filter}`
      );
    },
    saveThread: (actionPlanId: ObjectId, data: any, threadId?: ObjectId): Observable<any> => {
      if (threadId) {
        return this.http.patch(`${this.environment.api}actionplans/${actionPlanId}/comment_threads/${threadId}`, data);
      }
      return this.http.post(`${this.environment.api}actionplans/${actionPlanId}/comment_threads`, data);
    },
    saveComment: (actionPlanId: ObjectId, data: any, commentId?: ObjectId): Observable<any> => {
      if (commentId) {
        return this.http.patch(`${this.environment.api}actionplans/${actionPlanId}/comments/${commentId}`, data);
      }
      return this.http.post(`${this.environment.api}actionplans/${actionPlanId}/comments`, data);
    },
    updateActionStatus: (actionPlanId: ObjectId, actionId: string, status: string): Observable<void> => {
      return this.http.patch<void>(`${this.environment.api}actionplans/${actionPlanId}/actions/${actionId}/status`, {
        status,
      });
    },
    updateActionPlanning: (actionPlanId: ObjectId, actionId: string, planning: any): Observable<void> => {
      return this.http.patch<void>(
        `${this.environment.api}actionplans/${actionPlanId}/actions/${actionId}/planning`,
        planning
      );
    },
  };

  public surveys = {
    getSurveys: (
      page: number = 1,
      pageSize: number = 25,
      searchTerm?: string,
      extraFilter?: string,
      additionalFields?: string[],
      archived: boolean = false
    ): Observable<IApiListResponse<Partial<ISurvey>>> => {
      let filter = '';
      let archivedString = '';

      if (archived) {
        archivedString = `"_archived": {"$ne": null}`;
      } else {
        archivedString = `"_archived": null`;
      }

      if (searchTerm) {
        filter = `&where={${archivedString}, "general.title.value":{"$regex":".*(?i)${searchTerm}.*"}${
          extraFilter ? ', ' + extraFilter : ''
        }}`;
      } else if (extraFilter) {
        filter = `&where={${archivedString}, ${extraFilter}}`;
      } else {
        filter = `&where={${archivedString}}`;
      }

      let additionalFieldsString = '';
      if (additionalFields) {
        additionalFields.forEach((field) => {
          additionalFieldsString += `, "${field}": 1`;
        });
      }

      const projection = `&projection={"_id": 1, "published": 1, "general.thumbnail": 1, "general.title": 1, "general.defaultLanguage": 1, "general.uri": 1, "_paused": 1, "_archived": 1, "statistics": 1${additionalFieldsString}}`;
      return this.http.get<IApiListResponse<Partial<ISurvey>>>(
        `${this.environment.api}surveys?max_results=${pageSize}&page=${page}&sort=[("published", -1), ("_updated", -1)]${projection}${filter}`
      );
    },
    getLatestSurvey: (): Observable<Partial<ISurvey>> => {
      const projection = `&projection={"_id": 1, "published": 1, "general.thumbnail": 1, "general.title": 1, "general.defaultLanguage": 1, "statistics": 1}`;
      return this.http
        .get<IApiListResponse<Partial<ISurvey>>>(
          `${this.environment.api}surveys?where={"_archived": null, "statistics.responses": {"$gt": 0}}&sort=[("statistics._updated", -1)]&max_results=1&page=1${projection}`
        )
        .pipe(map((response) => response?._items[0]));
    },
    getSurveyById: (id: ObjectId): Observable<ISurvey> => {
      return this.http.get<ISurvey>(`${this.environment.api}surveys/${id}`);
    },
    saveSurvey: (surveyId: ObjectId, survey: Partial<ISurvey>): Observable<any> => {
      if (!surveyId) {
        return this.http.post(`${this.environment.api}surveys`, survey);
      }

      return this.http.patch(`${this.environment.api}surveys/${surveyId}`, survey);
    },
    publishSurvey: (surveyId: ObjectId, license?: ObjectId): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}surveys/${surveyId}/publish`, license ? { license } : {});
    },
    terminateSurvey: (surveyId: ObjectId): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}surveys/${surveyId}/terminate`, {});
    },
    deleteSurvey: (surveyId: string): Observable<any> => {
      return this.http.delete(`${this.environment.api}surveys/${surveyId}`);
    },
    getSurveyByUri: (uri: string): Observable<ISurvey> => {
      return this.http.get<ISurvey>(`${this.environment.api}surveys/uri/${uri}`);
    },
    getSurveysUriAvailability: (uri: string, currentSurvey?: string): Observable<{ available: boolean }> => {
      let currentFilter = '';
      if (currentSurvey) {
        currentFilter = `&current=${currentSurvey}`;
      }
      return this.http.get<{ available: boolean }>(
        `${this.environment.api}surveys/uri/${uri}?available=true${currentFilter}`
      );
    },
    getSurveyStats: (surveyId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/stats`);
    },
    sendReview: (surveyId: ObjectId, data: any, reviewid?: ObjectId): Observable<any> => {
      if (reviewid) {
        return this.http.put(`${this.environment.api}surveys/${surveyId}/reviews/${reviewid}`, data);
      } else {
        return this.http.post(`${this.environment.api}surveys/${surveyId}/reviews`, data);
      }
    },
    cloneSurvey: (surveyId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/clone`, {});
    },
    printSurveyUrl: (surveyId: ObjectId, languageCode: string): string => {
      return `${this.environment.api}surveys/${surveyId}/print/${languageCode}`;
    },
    getQRCodes: (surveyId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/qr`, {});
    },
    getSurveyTemplates: (): Observable<IApiListResponse<Partial<ISurvey>>> => {
      const projection = `{"_id": 1, "general": 1, "options": 1}`;
      return this.http
        .get<IApiListResponse<Partial<ISurvey>>>(`${this.environment.api}survey-templates?projection=${projection}`)
        .pipe(
          catchError((err) => {
            this.errorhandler(err);
            return throwError(err);
          })
        );
    },
    getSurveyTemplateById: (templateId: ObjectId): Observable<ISurvey> => {
      return this.http.get<ISurvey>(`${this.environment.api}survey-templates/${templateId}`).pipe(
        catchError((err) => {
          this.errorhandler(err);
          return throwError(err);
        })
      );
    },
  };

  public surveyResponses = {
    saveSurveyResponse: (surveyId: ObjectId, data: any, responseId: ObjectId): Observable<any> => {
      if (responseId) {
        return this.http.put(`${this.environment.api}surveys/${surveyId}/responses/${responseId}`, data);
      } else {
        return this.http.post(`${this.environment.api}surveys/${surveyId}/responses`, data);
      }
    },
    getSurveyResponses: (
      surveyId: ObjectId,
      page: number = 1,
      pageSize: number = 25,
      searchTerm?: string
    ): Observable<any> => {
      let filter: string = '';
      if (searchTerm) {
        filter = `&where={"_id":"${searchTerm}"}`;
      }
      const url = `${this.environment.api}surveys/${surveyId}/responses/list?max_results=${pageSize}&page=${page}&sort=[("_updated", -1)]${filter}`;
      return this.http.get(url);
    },
    deleteSurveyResponses: (surveyId: ObjectId): Observable<any> => {
      return this.http.delete(`${this.environment.api}surveys/${surveyId}/responses/list`);
    },
    deleteSurveyResponse: (surveyId: ObjectId, responseId: ObjectId): Observable<any> => {
      return this.http.delete(`${this.environment.api}surveys/${surveyId}/responses/list/${responseId}`);
    },
  };

  public analytics = {
    getChartByQuestions: (surveyId: ObjectId, data: any): Observable<any> => {
      const headers = new HttpHeaders().set('No-Clean', 'true');
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/analytics`, data, { headers });
    },
    getOtherValues: (surveyId: ObjectId, data: any): Observable<any> => {
      const headers = new HttpHeaders().set('No-Clean', 'true');
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/analytics`, data, { headers });
    },
    export: (surveyId: ObjectId, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/export`, data);
    },
    saveChart: (surveyId: ObjectId, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/charts`, data);
    },
    getCharts: (surveyId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/charts`);
    },
    getChartById: (surveyId: ObjectId, chartId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/charts/${chartId}`);
    },
    getExports: (surveyId: ObjectId, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(
        `${this.environment.api}surveys/${surveyId}/exports?max_results=${pageSize}&page=${page}&sort=[("_created", -1)]&embedded={"user":1}`
      );
    },
    getUploads: (surveyId: ObjectId, question: string, page: number = 1, pageSize: number = 25): Observable<any> => {
      return this.http.get(
        `${this.environment.api}surveys/${surveyId}/uploads?max_results=${pageSize}&page=${page}&where={"question":"${question}"}`
      );
    },
    getInteractionStats: (surveyId: ObjectId, stat: 'count-total' | 'count-respondent'): Observable<any> => {
      return this.http.get(`${this.environment.api}surveys/${surveyId}/responses/interactions/${stat}`).pipe(
        map((resp: any) => {
          return { mapInteractions: resp.map_interactions, imageInteractions: resp.image_interactions };
        })
      );
    },
    getMobilityStats: (
      surveyId: ObjectId,
      group: 'TOTAL' | 'TRANSPORT_MODE' | 'TRANSPORT_MODE_GROUP',
      filter?: { category?: string; page?: number }
    ): Observable<any> => {
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/mobility`, { group, filter });
    },
    getResponseStats: (surveyId: ObjectId, stat: string, aggregation?: string): Observable<any> => {
      const data = aggregation ? { aggregation } : {};
      return this.http.post(`${this.environment.api}surveys/${surveyId}/responses/statistics/${stat}`, data);
    },
  };

  public surveyReports = {
    getSurveyReports: (
      surveyId: ObjectId,
      opts: IApiListRequestOptions
    ): Observable<IApiListResponse<ISurveyReport>> => {
      const { page, pageSize } = opts;
      return this.http
        .get<IApiListResponse<ISurveyReport>>(
          `${this.environment.api}surveys/${surveyId}/reports?page=${page}&max_results=${pageSize}`
        )
        .pipe(
          catchError((err) => {
            this.errorhandler(err);
            return throwError(err);
          })
        );
    },
    getSurveyReportById: (surveyId: ObjectId, reportId: ObjectId): Observable<ISurveyReport> => {
      return this.http.get<ISurveyReport>(`${this.environment.api}surveys/${surveyId}/reports/${reportId}`).pipe(
        catchError((err) => {
          this.errorhandler(err);
          return throwError(err);
        })
      );
    },
    generateReport: (surveyId: ObjectId, data: ISurveyReport): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}surveys/${surveyId}/reports`, data).pipe(
        catchError((err) => {
          this.errorhandler(err);
          return throwError(err);
        })
      );
    },
  };

  public map = {
    getMaps: (
      page: number = 1,
      pageSize: number = 25,
      searchTerm?: string,
      extraFilter?: string
    ): Observable<IApiListResponse<Partial<IMapResult>>> => {
      let filter: string = '';
      if (searchTerm) {
        filter = `&where={"title.value":{"$regex":".*(?i)${searchTerm}.*"}${extraFilter ? ', ' + extraFilter : ''}}`;
      } else if (extraFilter) {
        filter = `&where={${extraFilter}}`;
      }

      const projection =
        '&projection={"_id": 1, "published": 1, "thumbnail": 1, "title": 1, "uri": 1, "defaultLanguage": 1, "counters": 1, "_paused": 1}';
      return this.http.get<IApiListResponse<Partial<IMapResult>>>(
        `${this.environment.api}maps?max_results=${pageSize}&page=${page}&sort=[("published", -1), ("_updated", -1)]${projection}${filter}`
      );
    },
    saveMap: (data: any, mapId?: ObjectId): Observable<any> => {
      if (!mapId) {
        return this.http.post(`${this.environment.api}maps`, data);
      } else {
        return this.http.patch(`${this.environment.api}maps/${mapId}`, data);
      }
    },
    getMapById: (mapId: ObjectId): Observable<IMapResult> => {
      return this.http.get<IMapResult>(`${this.environment.api}/maps/${mapId}`);
    },
    deleteMap: (mapId: ObjectId): Observable<any> => {
      return this.http.delete(`${this.environment.api}maps/${mapId}`);
    },
    getWmsProxy: (url: string): Observable<any> => {
      return this.http.get(`${this.environment.api}wms/${url}`, {
        responseType: 'text',
      });
    },
    getMapByUri: (uri: string): Observable<IMapResult> => {
      return this.http.get<IMapResult>(`${this.environment.api}maps/uri/${uri}`);
    },
    getMapsUriAvailability: (uri: string, currentMap?: string): Observable<{ available: boolean }> => {
      let currentFilter = '';
      if (currentMap) {
        currentFilter = `&current=${currentMap}`;
      }
      return this.http.get<{ available: boolean }>(
        `${this.environment.api}maps/uri/${uri}?available=true${currentFilter}`
      );
    },
    addView: (mapId: ObjectId): Observable<void> => {
      return this.http.post<void>(`${this.environment.api}maps/${mapId}/view`, {});
    },
    getQRCodes: (mapId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}maps/${mapId}/qr`, {});
    },
    cloneMap: (mapId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}maps/${mapId}/clone`, {});
    },
    getUploadById: (uploadId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}layers/${uploadId}`);
    },
    getMapsLinkedToSurvey: (surveyId: ObjectId): Observable<{ _items: Partial<IMapResult>[] }> => {
      return this.http.get<any>(
        `${this.environment.api}maps?where={"reference.survey":"${surveyId}"}&projection={"_id": 1, "reference": 1}`
      );
    },
  };

  public files = {
    uploadForSurvey: (file: File, surveyId: ObjectId, questionId: string): Observable<any> => {
      const data = new FormData();
      data.append('file', file);
      data.append('survey', surveyId);
      data.append('question', questionId);
      return this.http.post(`${this.environment.api}surveys/${surveyId}/uploads`, data, {
        reportProgress: true,
        observe: 'events',
      });
    },
    upload: (file: File | Blob, isThumbnail: boolean = false): Observable<any> => {
      const data = new FormData();
      data.append('file', file);
      if (isThumbnail) {
        data.append('thumbnail', `${isThumbnail}`);
      }
      return this.http.post(`${this.environment.api}uploads`, data, {
        reportProgress: true,
        observe: 'events',
      });
    },
    getFile: (fileId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}uploads/${fileId}`, {
        responseType: 'blob',
      });
    },
    getFileUrl: (url: string): Observable<SafeUrl> => {
      return this.http
        .get(url, { responseType: 'blob' })
        .pipe(map((e) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
    },
    removeFile: (fileId: string): Observable<any> => {
      return this.http.delete(`${this.environment.api}uploads/${fileId}`);
    },
  };

  public notifications = {
    getAllNotifications: (orgId: ObjectId, app?: MaplixApp): Observable<IApiListResponse<INotification>> => {
      let filter = '';
      if (app === MaplixApp.ENGAGE) {
        filter = `&where={"type": {"$ne": "LAYER"}}`;
      } else if (app === MaplixApp.EXPLORE) {
        filter = `&where={"type": {"$ne": "EXPORT"}}`;
      }
      return this.http.get<IApiListResponse<INotification>>(
        `${this.environment.api}notifications?where={"$or": [{"organisation": null}, {"organisation": "${orgId}"}]}&sort=[("_created", -1)]&max_results=100${filter}`
      );
    },
  };

  public announcements = {
    getAnnouncements: (page: number = 1, pageSize: number = 25): Observable<IApiListResponse<any>> => {
      return this.http.get<IApiListResponse<any>>(
        `${this.environment.api}announcements?sort=[("_created", -1)]&max_results=${pageSize}&page=${page}`
      );
    },
    getAnnouncementById: (announcementId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}announcements/${announcementId}`);
    },
    saveAnnouncement: (userName: string, data: any, announcementId?: ObjectId): Observable<any> => {
      if (announcementId) {
        return this.http.patch(`${this.environment.api}announcements/${announcementId}`, data);
      }

      data.creator = userName;
      return this.http.post(`${this.environment.api}announcements`, data);
    },
    publishAnnouncement: (announcementId: ObjectId): Observable<any> => {
      return this.http.post(`${this.environment.api}announcements/${announcementId}/publish`, {});
    },
  };

  public themes = {
    getThemes: (app: MaplixApp, page: number = 1, pageSize: number = 25, searchTerm?: string): Observable<any> => {
      let filter: string = `&where={"app": "${app}"}`;
      if (searchTerm) {
        filter = `&where={"app": "${app}", "name":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }
      return this.http.get(
        `${this.environment.api}themes?sort=[("_created", -1)]&max_results=${pageSize}&page=${page}${filter}`
      );
    },
    getThemeById: (themeId: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}themes/${themeId}`);
    },
    saveTheme: (data: ITheme, themeId?: ObjectId): Observable<any> => {
      if (themeId) {
        return this.http.patch(`${this.environment.api}themes/${themeId}`, data);
      }

      return this.http.post(`${this.environment.api}themes`, data);
    },
    deleteTheme: (themeId: ObjectId): Observable<void> => {
      return this.http.delete<void>(`${this.environment.api}themes/${themeId}`);
    },
  };

  public superuser = {
    getOrganisations: (page: number = 1, pageSize: number = 25, searchTerm?: string): Observable<any> => {
      let filter: string = '';
      if (searchTerm) {
        filter = `&where={"name":{"$regex":".*(?i)${searchTerm}.*"}}`;
      }
      return this.http.get(
        `${this.environment.api}superuser/organisations?sort=[("name", 1)]&max_results=${pageSize}&page=${page}${filter}`
      );
    },
    getOrganisationById: (id: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}superuser/organisations/${id}`);
    },
    saveOrganisation: (id: ObjectId, data: Partial<IOrganisation>): Observable<any> => {
      if (id) {
        return this.http.patch(`${this.environment.api}superuser/organisations/${id}`, data).pipe(
          catchError((err) => {
            this.errorhandler(err);
            return throwError(err);
          })
        );
      }

      data.members = [];
      return this.http.post(`${this.environment.api}superuser/organisations`, data).pipe(
        catchError((err) => {
          this.errorhandler(err);
          return throwError(err);
        })
      );
    },
    deactivateOrganisation: (id: ObjectId): Observable<void> => {
      return this.http.delete<void>(`${this.environment.api}superuser/organisations/${id}`).pipe(
        catchError((err) => {
          this.errorhandler(err);
          return throwError(err);
        })
      );
    },
    getOrganisationStats: (id: ObjectId): Observable<any> => {
      return this.http.get(`${this.environment.api}organisations/${id}/stats`);
    },
    getMembers: (orgId: string): Observable<any> => {
      return this.http.get(`${this.environment.api}superuser/organisations/${orgId}/members?embedded={"user":1}`);
    },
    inviteMember: (orgId: string, data: any): Observable<any> => {
      return this.http.post(`${this.environment.api}superuser/organisations/${orgId}/invite`, data);
    },
    saveLicense: (orgId: string, data: any, licenseId?: string): Observable<any> => {
      if (!licenseId) {
        return this.http.post(`${this.environment.api}superuser/organisations/${orgId}/licenses`, data);
      }

      return this.http.patch(`${this.environment.api}superuser/organisations/${orgId}/licenses/${licenseId}`, data);
    },
    getLicenses: (orgId: string): Observable<IApiListResponse<IOrganisationLicense>> => {
      return this.http
        .get<IApiListResponse<IOrganisationLicense>>(
          `${this.environment.api}superuser/organisations/${orgId}/licenses?sort=[("startdate", -1)]`
        )
        .pipe(
          map((obj) => {
            obj._items = obj._items.map((item) => {
              item.startdate = new Date(item.startdate);
              item.enddate = new Date(item.enddate);
              return item;
            });
            return obj;
          })
        );
    },
    getLicense: (orgId: string, licenseId: string): Observable<IOrganisationLicense> => {
      return this.http
        .get<IOrganisationLicense>(`${this.environment.api}superuser/organisations/${orgId}/licenses/${licenseId}`)
        .pipe(
          map((obj) => {
            obj.startdate = new Date(obj.startdate);
            obj.enddate = new Date(obj.enddate);
            return obj;
          })
        );
    },
    getSurveyTemplates: (opts: IApiListRequestOptions): Observable<IApiListResponse<Partial<ISurvey>>> => {
      const projection = `{"_id": 1, "published": 1, "general": 1, "options": 1}`;
      const { page, pageSize } = opts;
      return this.http
        .get<IApiListResponse<Partial<ISurvey>>>(
          `${this.environment.api}superuser/survey-templates?page=${page}&max_results=${pageSize}&projection=${projection}`
        )
        .pipe(
          catchError((err) => {
            this.errorhandler(err);
            return throwError(err);
          })
        );
    },
    getSurveyTemplateById: (templateId: ObjectId): Observable<ISurvey> => {
      return this.http.get<ISurvey>(`${this.environment.api}superuser/survey-templates/${templateId}`);
    },
    saveSurveyTemplate: (templateId: ObjectId, survey: Partial<ISurvey>): Observable<any> => {
      if (!templateId) {
        return this.http.post(`${this.environment.api}superuser/survey-templates`, survey);
      }

      return this.http.patch(`${this.environment.api}superuser/survey-templates/${templateId}`, survey);
    },
    deleteSurveyTemplate: (templateId: ObjectId): Observable<void> => {
      return this.http.delete<void>(`${this.environment.api}superuser/survey-templates/${templateId}`);
    },
  };

  public errorhandler(error) {
    if (error.customMessage) {
      this.notification.danger(error.customMessage, error.status);
    } else if (error.status == 401) {
      this.notification.danger("You don't have the rights to perform this operation", error.status);
    } else if (error.status == 403) {
      this.notification.danger("You don't have the rights to perform this operation", error.status);
    } else if (error.status == 404) {
      this.notification.danger('Data was not found', error.status);
    } else if (error.status) {
      this.notification.danger('Something went wrong', error.status);
      console.error(error);
    } else {
      this.notification.danger(error);
    }
  }

  public successhandler(msg: string, title?: string, config?: Partial<IndividualConfig>) {
    return this.notification.success(msg, title, config);
  }
}
