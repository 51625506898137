import { ITheme } from '../collections';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { I18n } from '../i18n';
import { ObjectId } from '../object-id';
import { IQRCode } from './survey';
import { IMapDataLayer } from '../map-data-layer';
import { ILanguage } from '../language';

export enum MapType {
  MAP = 'MAP',
  IMAGE = 'IMAGE',
}

export interface IMapResult {
  _id?: ObjectId;
  thumbnail?: string;
  type: MapType;
  published?: boolean;
  title: I18n[];
  subtitle: I18n[];
  uri: string;
  center: Coordinate;
  zoom: number;
  baseLayer: string;
  dataLayers: IMapDataLayer[];
  pageid?: number;
  survey?: ObjectId;
  organisation: ObjectId;
  image?: string;
  imageExtent?: Extent;
  languages: ILanguage[];
  defaultLanguage: ILanguage;
  period: Date[];
  qr: IQRCode;
  mapImage?: string; // Legacy should not be used anymore
  options: IMapOptions;
  counters?: any;
  _example?: boolean;
  _paused?: Date;
  reference?: IMapReference;
}

export interface IMapReference {
  survey: ObjectId;
  type: string;
  page?: number;
  interaction?: string;
}

export interface IMapOptions {
  theme: ITheme;
  minZoom?: number;
  maxZoom?: number;
}
