import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ContentChild,
} from '@angular/core';
import { GridCardHeaderTemplateDirective } from './ng-templates.directive';

@Component({
  selector: 'maplix-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCardComponent {
  @Input()
  public readonly icon: string;

  @Input()
  public readonly image: string;

  @Input()
  public readonly type: string;

  @Input()
  public readonly typeHelp: string;

  @Input()
  public readonly typeHelpContainer: string = 'body';

  @Input()
  public readonly name: string;

  @Input()
  public readonly nameClass: string = '';

  @Input()
  public readonly description: string;

  @Input()
  public readonly isSelected: boolean;

  @Input()
  public readonly disabled: boolean;

  @Input()
  private readonly badgeType: string = 'light-primary';

  @Input()
  public readonly badgeText: string;
  public get badgeClass() {
    return `badge badge-${this.badgeType} d-flex align-items-center`;
  }

  @Input()
  public readonly badgeIcon: string;
  public get badgeIconClass() {
    return this.badgeText
      ? this.resizeOnMobile
        ? `${this.badgeIcon} mr-md-1`
        : `${this.badgeIcon} mr-1`
      : this.badgeIcon;
  }

  @Input()
  public readonly size: string = 'md';

  @Input()
  public readonly bottomBorder: string;

  @Input()
  public dropdownItems: {
    icon?: string;
    label: string;
    action: string;
    confirmOptions?: any;
    customClass?: string;
    permissions?: string;
  }[];

  @Input()
  public resizeOnMobile: boolean;

  @Input()
  public layoutSize: string;

  @Output()
  private action: EventEmitter<any> = new EventEmitter();

  @Output()
  public readonly onDelete: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onInfo: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onSwitch: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onClickDropdown: EventEmitter<string> = new EventEmitter();

  @ContentChild(GridCardHeaderTemplateDirective, { read: TemplateRef }) headerTemplate: TemplateRef<any>;

  private buttonClicked: boolean = false;

  constructor() {}

  public truncText(text: string, length: number = 80) {
    if (!text) {
      return;
    }

    if (text.length > length) {
      return text.slice(0, length) + '...';
    }

    return text;
  }

  public onClickCard() {
    if (this.disabled) {
      return;
    }

    setTimeout(() => {
      if (!this.buttonClicked) {
        this.action.emit();
      }

      this.buttonClicked = false;
    });
  }

  public onClickDelete() {
    this.buttonClicked = true;
    this.onDelete.emit();
  }

  public onClickInfo() {
    this.buttonClicked = true;
    this.onInfo.emit();
  }

  public onClickSwitch() {
    this.buttonClicked = true;
    this.onSwitch.emit();
  }

  public onClickTypeHelp() {
    this.buttonClicked = true;
  }

  public onOpenDropdown() {
    this.buttonClicked = true;
  }

  public onOpenDropdownConfirm() {
    this.buttonClicked = true;
  }

  public onDropdownAction(action: string) {
    this.buttonClicked = true;
    this.onClickDropdown.emit(action);
  }
}
