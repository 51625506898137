import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@maplix/api';
import { RealmService } from '@maplix/realm';
import { IOrganisation, IOrganisationLicense, IOrganisationMember, IUserDetails } from '@maplix/utils';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private userDetails: BehaviorSubject<IUserDetails> = new BehaviorSubject(undefined);
  public userDetails$: Observable<IUserDetails> = this.userDetails.asObservable();

  private activeWorkspace: BehaviorSubject<IOrganisation> = new BehaviorSubject(undefined);
  public activeWorkspace$: Observable<IOrganisation> = this.activeWorkspace.asObservable();

  private activeMember: BehaviorSubject<IOrganisationMember> = new BehaviorSubject(undefined);
  public activeMember$: Observable<IOrganisationMember> = this.activeMember.asObservable();

  private projectLicenses: BehaviorSubject<IOrganisationLicense[]> = new BehaviorSubject([]);
  public projectLicenses$: Observable<IOrganisationLicense[]> = this.projectLicenses.asObservable();

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    private cookie: CookieService,
    private realmService: RealmService,
    private api: ApiService
  ) {}

  public getUserDetails() {
    return this.userDetails.value;
  }

  public async setUserDetails(details: any): Promise<void> {
    this.userDetails.next(details);

    this.cookie.set(
      `userDetails${this.environment.env}`,
      JSON.stringify({ _id: details?._id }),
      30,
      '/',
      this.environment.cookieDomain
    );
  }

  public getActiveWorkspace() {
    return this.activeWorkspace.value;
  }

  public setActiveWorkspace(workspace: IOrganisation): void {
    this.activeWorkspace.next(workspace);

    this.cookie.set(
      `activeWorkspace${this.environment.env}`,
      JSON.stringify({ _id: workspace?._id }),
      30,
      '/',
      this.environment.cookieDomain
    );
  }

  public setActiveMember(member: IOrganisationMember): void {
    this.activeMember.next(member);
  }

  public getActiveMember() {
    return this.activeMember.value;
  }

  public setProjectLicenses(licenses: IOrganisationLicense[]) {
    console.log(licenses);
    this.projectLicenses.next(licenses);
  }

  public getProjectLicenses() {
    return this.projectLicenses.value;
  }
}
