import { IMapDataLayer } from '../map-data-layer';
import { Coordinate } from 'ol/coordinate';
import { IStyle } from '../analytics-style';
import { I18n } from '../i18n';
import { IImageInteraction, IMapInteraction } from '../interaction';
import { ILanguage } from '../language';
import { ObjectId } from '../object-id';
import { ITheme } from './theme';

export type GroupOperator = 'AND' | 'OR';

export type ConditionType = null | 'question' | 'category' | 'transportMode';

export interface IQRCode {
  [key: string]: {
    black: string;
    maplix: string;
    theme: string;
  };
}

export interface ISurvey {
  _id?: ObjectId;
  organisation: ObjectId;
  template?: ObjectId;
  published?: boolean;
  general: ISurveyGeneral;
  questions: ISurveyContent;
  options: ISurveyOptions;
  statistics?: any;
  _created?: Date;
  _updated?: Date;
  _auth?: string;
  _example?: boolean;
  _paused?: Date;
  _archived?: Date;
  _license?: ObjectId;
}

export enum PrivacyType {
  TEXT = 'TEXT',
  URL = 'URL',
  UPLOAD = 'UPLOAD',
}

export interface ISurveyGeneral {
  uri: string;
  title: I18n[];
  subtitle: I18n[];
  languages: ILanguage[];
  defaultLanguage: ILanguage;
  period: Date[];
  intro: I18n[];
  introImage: string;
  privacyType: PrivacyType;
  privacy: I18n[];
  privacyUrl: I18n[];
  privacyFile: I18n[];
  privacyChecks: I18n[];
  outro: I18n[];
  outroImage: string;
  qr: IQRCode;
  thumbnail?: string;
  redirectUrl: I18n[];
  socialShareWidget: boolean;
}

export interface ISurveyContent {
  pages: ISurveyPage[];
  questions: ISurveyQuestion[];
  mapInteractions: ISurveyMap[]; //ignore the IOldSurveyMap in the first place, later we can migrate these (hoogstraten)
  imageInteractions: ISurveyImage[];
}

export interface ISurveyOptions {
  theme: ITheme;
  isTemplate?: boolean;
  template?: ISurveyTemplateInfo;
  _customizable: boolean;
}

export interface ISurveyTemplateInfo {
  title: I18n[];
  description: I18n[];
  longDescription: I18n[];
  thumbnail: string;
  tags?: I18n[][];
}

export type PageType = 'question' | 'map' | 'image' | 'intro' | 'outro' | 'section-intro';
export interface ISurveyPage {
  id: number | 'intro' | 'outro';
  type: PageType;
  name?: I18n[];
  description?: I18n[];
  image?: string;
  relations?: IRelation[];
  _archived?: Date;
}

export interface ISurveyQuestion {
  id: string;
  pageid: number;
  category?: string;
  displayOrder: number;
  alias: I18n[];
  question: I18n[];
  helptext: I18n[];
  type: number;
  fieldValues: IFieldValue[];
  subquestions: ISubQuestion[];
  relations: IRelation[];
  options: IQuestionOptions;
  _archived?: Date;
}

export interface ISurveyMap {
  id: number;
  pageid: number;
  description: I18n[];
  defaultBaseLayer: string;
  center: Coordinate;
  zoom: number; // > 0 && < 23
  categories: IMapInteraction[];
  previousInteractions?: number[];
  infoFeatures?: any;
  dataLayers?: IMapDataLayer[];
  _archived?: Date;
}

export interface IOldSurveyMap extends ISurveyMap {
  value: string | number;
  label: I18n[];
  drawOptions: any;
  style: IStyle;
}

export interface ISurveyImage {
  id: number;
  pageid: number;
  description: I18n[];
  categories: IImageInteraction[];
  infoFeatures?: any;
  image?: string;
  imageExtent?: [number, number, number, number];
  dataLayers?: IMapDataLayer[];
  _archived?: Date;
}

export interface IQuestionOptions {
  required?: boolean;
  multiple?: boolean;
  className?: string;
  minLabel?: I18n[];
  middleLabel?: I18n[];
  maxLabel?: I18n[];
  stars?: number;
  inlineText?: boolean;
  frontLabel?: IFieldValue[];
  backLabel?: IFieldValue[];
  otherOption?: boolean;
  naOption?: boolean;
  dateType?: null | 'regular' | 'daterange';
  minDate?: Date;
  maxDate?: Date;
  textType?: null | 'text' | 'number' | 'email';
  textRows?: number;
  scaleMin?: number;
  scaleMax?: number; // must be >= scaleMin
  stepSize?: number; // must be > 0
  numberMin?: number;
  numberMax?: number; // must be >= numberMin
  minSelected?: number;
  maxSelected?: number; // must be >= minSelected,
  masked?: string[];
  budgetSuffix?: I18n[];
  showLabel?: boolean;
  showBubble?: boolean;
}

export interface IFieldValue {
  value: string;
  label?: I18n[];
  image?: string;
  _archived?: Date;
}

export interface ISubQuestion {
  subquestionid: string;
  label: I18n[];
  _archived?: Date;
}

export interface IRelation {
  type: ConditionType;
  order?: number;
  questionid?: string;
  subquestionid?: string;
  categoryid?: string;
  transportMode?: string;
  value?: any;
  operator?: string;
  groupOperator: GroupOperator;
}
