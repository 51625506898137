import { IUserDetails } from './users';
import { ObjectId } from '../object-id';
import { ITheme } from './theme';
import { ILanguage } from '../language';
import { I18n } from '../i18n';
import { Coordinate } from 'ol/coordinate';
import { IMapDataLayer } from '../map-data-layer';

export interface IOrganisation {
  _id?: ObjectId;
  type: string;
  name: string;
  image?: string;
  theme: ITheme;
  languages: ILanguage[];
  defaultLanguage: ILanguage;
  billingDetails: IBilling;
  urls: IOrganisationUrls;
  mapOptions: IOrganisationMapOptions;
  members?: IOrganisationMember[];
}

export interface IOrganisationMember {
  _id?: ObjectId;
  organisation?: ObjectId;
  user: ObjectId | IUserDetails;
  roles?: string[];
}

export interface IOrganisationMemberEmbedded extends IOrganisationMember {
  user: IUserDetails;
}

export interface IBilling {
  phone?: string;
  email: string;
  country?: string;
}

export interface IOrganisationUrls {
  contactUrl: I18n[];
  termsUrl: I18n[];
  privacyUrl: I18n[];
}

export interface IOrganisationMapOptions {
  center: Coordinate;
  zoom: number;
  dataLayers: IMapDataLayer[];
}

export interface IOrganisationLicense {
  _id?: ObjectId;
  organisation: ObjectId;
  type: LicenseType;
  startdate: Date;
  enddate: Date;
  modules: LicenseModule[];
  project?: IOrganisationLicenseProject;
}

export interface IOrganisationLicenseProject {
  name: string;
  actionplans: IOrganisationLicenseCounter;
  surveys: IOrganisationLicenseCounter;
  maps: IOrganisationLicenseCounter;
}

export interface IOrganisationLicenseCounter {
  allowed: number;
  remaining: number;
}

export enum LicenseType {
  FREE_TRIAL = 'FREE_TRIAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  PROJECT = 'PROJECT',
}

export enum LicenseModule {
  ACTIONPLANS = 'ACTIONPLANS',
  SURVEYS = 'SURVEYS',
  COMMUNICATION = 'COMMUNICATION',
}
